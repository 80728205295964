$primary: #367abe;

@import "node_modules/bulma/bulma.sass";

$blue: lighten(#367abe, 5%);
$blue-dark: #367abe;
$blue-darker: darken(#367abe, 10%);

.has-background-image {
	background-image: url(../images/hero-bg.jpg);
	background-position: 0 center;
	background-size: cover;
}

.has-background-image-banner {
	background-image: url(../images/hero-sm-bg.jpg);
	background-position: 0 bottom;
	background-size: cover;
}

.has-padding {
	padding: 32px 15px;
}

.is-blue {
	color: $blue !important;
	&.is-active {
		color: $blue-darker !important;
	}
}

.is-white {
	color: white !important;
}

.has-text-shadow {
	text-shadow: black 1px 1px 10px;
}

.two-column-list {
	li {
		color: #367abe;

		> span {
			color: $grey-dark;
		}
	}
}

.hero-body .title {
	color: black;
}

.has-yooge-text {
	font-size: 28px;
	padding: 24px;
};


@media screen and (min-width:769px) {
	.has-yooge-text {
		font-size: 42px !important;
		padding: 24px;
	};

	.hero-body .title {
		font-size: 38px;
	}
	
	.nav-item img {
		max-height: 4.75em;
	}

	.nav {
		min-height: 7.5em;
	}

	a.nav-item.is-yooge {
		font-size: 32px;
	}

	.two-column-list {
		display: flex;
		flex-wrap: wrap;

		li {
			flex: 50%;
		}
	}
}